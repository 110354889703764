import { odyssealHttp } from "../utils/http";

export const retrieveModels = () => {
  return odyssealHttp
    .get("/api/models")
    .then((resp) => resp.data["hydra:member"]);
};

export const retrieveTrainingHistory = (filters) => {
  return odyssealHttp
    .get(`/api/users/me/training-history`, { params: filters })
    .then((resp) => resp.data);
};

export const retrieveTotalTrainingHistory = () => {
  return odyssealHttp
    .get("/api/users/me/training-history/total")
    .then((resp) => resp.data);
};

export const exportTrainingHistoryExcel = async (filters) => {
  const response = await odyssealHttp.get(
    "/api/users/me/training-history/export",
    { params: filters, responseType: "blob" },
  );

  const url = window.URL.createObjectURL(response.data);
  const link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", "mes_formation.xlsx");

  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};
