import React, { useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import * as yup from "yup";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  Refresh as RefreshIcon,
  Search as SearchIcon,
} from "@material-ui/icons";

import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";
import { retrieveModels } from "../../http/odysseal";

const TrainingToolbar = ({ onFilter, exercice }) => {
  const [resetFields, setResetFields] = useState(false);

  const modelQuery = useQuery({
    queryKey: ["odysseal", "models"],
    queryFn: () => retrieveModels(),
  });

  const [events, trainings] = useMemo(() => {
    if (!modelQuery.data) return [[], []];

    return [
      modelQuery.data.filter((item) => item.category === "EVENT"),
      modelQuery.data.filter((item) => item.category === "FORMATION"),
    ];
  }, [modelQuery.data]);

  const participationsStatus = {
    TRAINED: "Consultant",
    INVITED: "Invité",
    COHOST: "Co-animateur",
    HOST: "Animateur",
    TRAINER: "Formateur",
  };

  const track = useTrackingContext();

  const defaultValues = {
    formation: "",
    evenement: "",
    validationFormation: "",
    statutParticipant: "",
    exercice: exercice,
    ville: "",
  };

  const schema = yup.object().shape({
    formation: yup.string(),
    evenement: yup.string(),
    ville: yup.string(),
    statutParticipant: yup
      .string()
      .oneOf(["", ...Object.keys(participationsStatus)]),
    validationFormation: yup.string().oneOf(["", "true", "false"]),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    onFilter({ ...data, exercice: exercice });
    track.click(ACTION_CLICK.SET_FILTER);
  };

  const onReset = () => {
    setResetFields(!resetFields);
    reset();
    onFilter({ ...defaultValues });
    track.click(ACTION_CLICK.RESET_FILTER);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={6} lg={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="formation" shrink={true}>
              Formation
            </InputLabel>
            <Controller
              name="formation"
              control={control}
              render={({ field }) => (
                <Select {...field} labelId="formation">
                  <MenuItem value="">
                    <em>Toutes</em>
                  </MenuItem>
                  {trainings.map((training, index) => (
                    <MenuItem key={index} value={training["id"]}>
                      {training.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="evenement" shrink={true}>
              Evènement
            </InputLabel>
            <Controller
              name="evenement"
              control={control}
              render={({ field }) => (
                <Select {...field} labelId="evenement">
                  <MenuItem value="">
                    <em>Toutes</em>
                  </MenuItem>
                  {events.map((event, index) => (
                    <MenuItem key={index} value={event.id}>
                      {event.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={4}>
          <Controller
            name="ville"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Ville"
                variant="filled"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="statutParticipant" shrink={true}>
              Statut du participant
            </InputLabel>
            <Controller
              name="statutParticipant"
              control={control}
              render={({ field }) => (
                <Select {...field} labelId="statutParticipant">
                  <MenuItem value="">
                    <em>Tous</em>
                  </MenuItem>
                  {Object.entries(participationsStatus).map(
                    ([key, value], index) => (
                      <MenuItem key={index} value={key}>
                        {value}
                      </MenuItem>
                    ),
                  )}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="validationFormation" shrink={true}>
              Validation de la formation
            </InputLabel>
            <Controller
              name="validationFormation"
              control={control}
              render={({ field }) => (
                <Select {...field} labelId="validationFormation">
                  <MenuItem value="">
                    <em>Tous</em>
                  </MenuItem>
                  <MenuItem value="true">Validé</MenuItem>
                  <MenuItem value="false">Non Validé</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={4} container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              startIcon={<SearchIcon />}
              type="submit"
            >
              Filtrer
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<RefreshIcon />}
              onClick={() => onReset()}
            >
              Réinitialiser
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

TrainingToolbar.defaultProps = {
  agentId: null,
};

TrainingToolbar.propTypes = {
  agentId: PropTypes.string,
};
export default TrainingToolbar;
