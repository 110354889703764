import PropTypes from "prop-types";
import React, { useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { useQuery } from "react-query";
import renderCellExpand from "../../utils/renderCellExpand";
import { retrieveTrainingHistory } from "../../http/odysseal";
import { dateColumn } from "../../utils/dataGridColumns";

const TrainingList = ({ filters }) => {
  const [pageSize, setPageSize] = useState(25);

  const query = useQuery({
    queryKey: ["odysseal", "participations", { filters }],
    queryFn: () => retrieveTrainingHistory(filters),
    placeholderData: [],
  });

  const columns = [
    {
      field: "training",
      headerName: "Formation",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "event",
      headerName: "Evènement",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "city",
      headerName: "Ville",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "date",
      ...dateColumn,
      headerName: "Date",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "status_participant",
      headerName: "Statut du participant",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "status_inscription",
      headerName: "Statut inscription",
      flex: 1,
      renderCell: renderCellExpand,
    },
  ];

  return (
    <DataGrid
      loading={query.isLoading}
      rows={query.data}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize.pageSize)}
      autoHeight
      disableColumnFilter
      disableSelectionOnClick
      disableColumnMenu
      disableColumnReorder
      pagination
      getRowId={(row) => row.uuid}
    />
  );
};

TrainingList.propTypes = {
  filters: PropTypes.object,
};

export default TrainingList;
